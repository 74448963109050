import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog25/image1.png"
import image2 from "../../images/blog25/image2.png"

export default function IsCaffeineBadForUs(){
    return(
        <BlogPostOld
            src={image}
            title={"Joints Can Hurt for No Reason"}
            summary={"I have become a BIG fan of Dr. Howard Luks and his content that he shares with his followers. He is an orthopedic surgeon who is incredibly well read and does a fantastic job sharing how current healthcare guidelines are falling short. \n"}
            date={"Oct. 3, 2022"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}
        >
            <>
                <p>
                    I have become a BIG fan of Dr. Howard Luks and his content that he shares
                    with his followers. He is an orthopedic surgeon who is incredibly well read
                    and does a fantastic job sharing how current healthcare guidelines are
                    falling short.&nbsp;
                </p>
                <p>
                    Anyway here are some serious gems from his blog{" "}
                    <a
                        href="https://www.howardluksmd.com/sometimes-our-joints-just-hurt-and-its-ok-not-to-know-why/?fbclid=IwAR0oeBEt6_EUwhN-_l16F5S0ADv7xFmItHg-1NDNTDYMfWxAazLwCouQqFk"
                        target="_blank"
                        rel="noopener"
                    >
                        “Sometimes our joints just hurt, and it’s ok not to know why”
                    </a>
                    . I highly recommend reading it in its entirety - it is extremely well
                    written with common cases (one of which I will share word for word in hopes
                    it peaks your interest enough to go read).&nbsp;
                </p>
                <p>
                    “Imagine this; you’re a runner, you’re 52… there’s a 20% chance that you
                    have a meniscus tear. Remember, these tears are very often not a cause of
                    pain. One day your knee hurts… well— because these things happen. You go for
                    an MRI. The meniscus tear is seen… but we can’t date these tears; we do not
                    know when it occurred. Now you are exposed to the rising overtreatment wave
                    in the US healthcare system. You might stop running because you think you’re
                    injuring yourself. You might be told to stop running. Heck, you might be
                    told you “need” an operation. All because you had an age-appropriate finding
                    and some knee pain. How many 50+-year-old runners never have knee pain? Very
                    few. This scenario is repeated thousands of times each day across our
                    country. Many athletes will suffer financially, emotionally, and physically
                    because of it.”
                </p>
                <p>
                    If this doesn’t peak your interest - I don’t know what will. I will try to
                    get you to go read one more time{" "}
                    <a
                        href="https://www.howardluksmd.com/sometimes-our-joints-just-hurt-and-its-ok-not-to-know-why/?fbclid=IwAR0oeBEt6_EUwhN-_l16F5S0ADv7xFmItHg-1NDNTDYMfWxAazLwCouQqFk"
                        target="_blank"
                        rel="noopener"
                    >
                        here
                    </a>
                    .&nbsp;
                </p>
                <p>
                    If you are pressed for time, I put together some of my favorite quotes from
                    the blog.
                </p>
                <p>
                    “Paired with a doctor who listens, your story, your description, and our
                    examination are often more successful than an MRI at determining what
                    hurts.”
                </p>
                <p>
                    “An MRI shows structures— ligaments, bones, cartilage, tendon, and muscle.
                    An MRI cannot show us what hurts.”
                </p>
                <h2 className={"text-center"}>Many MRI “abnormalities” are, well… normal.</h2>
                <p>
                    “Age-appropriate” findings on MRI readings are vastly under-appreciated. If
                    you are over 50,{" "}
                    <span style={{ textDecoration: "underline" }}>
      <em>have no pain</em>
    </span>
                    , and have an MRI, you have at least a …
                </p>
                <ul>
                    <li>A 20% chance of having a meniscus abnormality.</li>
                    <li>
                        An 80% chance of something being listed as an abnormality in your knee.
                    </li>
                    <li>A 10-15% or higher chance of having a rotator cuff tear.</li>
                    <li>An 80% chance of having a disc bulge in your back or neck.”</li>
                </ul>
                <img src={image2} alt={"blog image"}/>
                <p>
                    “We can
                    <a
                        href="https://www.howardluksmd.com/running-meniscus-tear/"
                        target="_blank"
                        rel="noopener"
                    >
                        {" "}
                        run with meniscus tears
                    </a>
                    … many of us do and don’t even know we have one.{" "}
                    <a
                        href="https://www.howardluksmd.com/osteoarthritis-and-running-can-they-co-exist/"
                        target="_blank"
                        rel="noopener"
                    >
                        We can run if we have osteoarthritis
                    </a>
                    , and running does not cause osteoarthritis, and running
                    <a
                        href="https://www.howardluksmd.com/does-exercise-cause-osteoarthritis/"
                        target="_blank"
                        rel="noopener"
                    >
                        {" "}
                        does not cause your osteoarthritis to worsen.
                    </a>
                    ”
                </p>
                <p>
                    “We dramatically underestimate the time it will take for discomfort,
                    swelling, etc., to resolve. Many overuse injuries such as rotator
                    cuff-related pain,{" "}
                    <a
                        href="https://www.howardluksmd.com/achilles-tendon-pain-tender-lump/"
                        target="_blank"
                        rel="noopener"
                    >
                        achilles tendon
                    </a>{" "}
                    issues, tennis elbow, hamstring issues can take up to a year or more to
                    resolve.”
                </p>
                <p>
                    I know we all really want to know the cause of what is giving us pain but in
                    reality pain is hard. I really don’t believe pain in musculoskeletal
                    conditions can be given such a simple cause.&nbsp;
                </p>
                <p>
                    If we took our energy we spent worrying about what is causing pain and
                    directed it into implementing healthy lifestyle habits and routines - we
                    would be a MUCH healthier society.
                </p>
            </>

        </BlogPostOld>

    )
}